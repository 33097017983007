
/*=========================================================================*/

/* [Global Variables] - http://sass-lang.com/guide */

$color-pink: rgb(232, 34, 119);
$color-blue-light: rgb(106, 152, 179);
$color-grey-med: rgb(65, 64, 66);
$color-grey-light: rgb(241, 242, 242);

$color-grey-light-pdf: rgb(201, 202, 202);


$font-default: 'Open Sans', sans-serif;

/*=========================================================================*/
